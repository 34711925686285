// libs
import React, { SVGProps } from 'react';

// components
import {
  BottomSheetProps,
  Button,
  ButtonProps,
  DeprecatedIconButtonProps,
  Dialog,
  TypographyVariants,
} from '@lyearn/ui';

import { DialogComponent } from '../DialogComponent';

export interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
  description?: string | React.ReactNode;
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  avatar?: {
    src?: string | null;
    className?: string;
  };
  onBack?: () => void;
  title: string | React.ReactNode;
  classes?: {
    description?: string;
    title?: string;
  };
  variants?: {
    title?: TypographyVariants;
    description?: TypographyVariants;
  };
}

export interface ActionType {
  className?: string;
  discardText?: string | React.ReactNode; // default Cancel
  onSubmit: () => void;
  onSecondarySubmit?: () => void;
  secondaryVariant?: React.ComponentProps<typeof Button>['variant'];
  text?: string | React.ReactNode;
  disabled?: boolean;
  isSubmitting?: boolean;
  isSubmittingSecondary?: boolean;
  appearance?: React.ComponentProps<typeof Button>['appearance'];
  endIcon?: React.ReactNode;
  classes?: {
    discard?: string;
    action?: string;
    buttonContainer?: string;
  };
  TertiaryAction?: React.ReactNode;
  buttonSize?: ButtonProps['size'];
}

export enum ModalVariant {
  FullScreen = 'FULL_SCREEN',
  FullHeight = 'FULL_HEIGHT',
  ExtraWide = 'EXTRA_WIDE',
  Wide = 'WIDE',
}

export interface ModalProps {
  action?: ActionType;
  children?: React.ReactNode;
  variant?: ModalVariant;
  renderHeader?: (header?: HeaderProps) => React.ReactNode;
  renderSubFooter?: () => React.ReactNode;
  header?: HeaderProps;
  onClose: () => void;
  showCloseIcon?: boolean;
  bottomSheetContainerClassName?: string;
  showCloseBtn?: boolean;
  open?: boolean;
  classes?: React.ComponentProps<typeof DialogComponent>['classes'];
  closeButton?: {
    size?: DeprecatedIconButtonProps['size'];
    className?: string;
  };
  bottomSheetClasses?: BottomSheetProps['classes'];
  TransitionComponent?: React.ComponentProps<typeof Dialog>['TransitionComponent'];
  dialogProps?: Partial<React.ComponentProps<typeof Dialog>>;
  className?: string;
  hideOnScroll?: boolean;
  root?: React.ReactNode;
  footer?: React.ReactNode;
  renderAs?: 'dialog' | 'bottomSheet';
}

export interface DialogContentProps {
  children: React.ReactNode;
  className?: string;
}

interface ListItem {
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  primaryText?: string;
  text: string | JSX.Element;
}

export interface DialogListProps {
  className?: string;
  items: ListItem[];
}

export interface UploaderProps {
  action: ActionType;
  children: React.ReactNode;
  header: HeaderProps;
  isMultiple?: boolean;
  onClose: () => void;
}
