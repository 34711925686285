import { KnowledgeAssetSubTypeEnum } from '@/types/schema';

import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const KNOWLEDGE_ASSET_PARENT_ROUTE = '/knowledge-asset';
const ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${KNOWLEDGE_ASSET_PARENT_ROUTE}`;

const knowledgeAssetAdminRoutes = {
  KnowledgeAssetTemplates: (type: KnowledgeAssetSubTypeEnum) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/templates`,
  KnowledgeAssetCreate: (type: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/create`,
  KnowledgeAssetDetails: (id: string, type: string = KnowledgeAssetSubTypeEnum.Solution) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${id}/details`,
  KnowledgeAssetReports: (id: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${id}/reports`,
  KnowledgeAssetEditor: (id: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${id}/edit`,
  KnowledgeAssetPersonaManager: (type: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}`,
};

export const knowledgeAssetLearnerRoutes = {
  KnowledgeAssetDetails: (id: string, type: string = KnowledgeAssetSubTypeEnum.Solution) =>
    `${KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${id}/details`,
};

export default knowledgeAssetAdminRoutes;
