export const LANGUAGE_I18N_MAP: Record<string, string> = {
  English: 'en-US',
  Hindi: 'hi',
  Marathi: 'mr',
  Tamil: 'ta',
  Telugu: 'te',
  Kannada: 'kn',
  Gujarati: 'gu',
  Spanish: 'es',
  Arabic: 'ar',
  Bengali: 'bn',
  Malayalam: 'ml',
};

export const LANGUAGE_I18N_MAP_REVERSE: Record<string, string> = {
  ar: 'ARABIC',
  bn: 'BENGALI',
  ch: 'CHINESE',
  'en-US': 'ENGLISH',
  fr: 'FRENCH',
  hi: 'HINDI',
  mr: 'MARATHI',
  es: 'SPANISH',
  ta: 'TAMIL',
  te: 'TELUGU',
  kn: 'KANNADA',
  gu: 'GUJARATI',
  ml: 'MALAYALAM',
};
