import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/path';
const ADMIN_PATH_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const adminPathRoutes = {
  PathCreator: () => `${ADMIN_PATH_PARENT_ROUTE}/create`,
  PathEditor: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/edit`,
  PathDuplicate: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/duplicate`,
  PathReport: (pathId: string) => adminPathRoutes.PathLearnersReport(pathId),
  PathLearnersReport: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/reports/learners`,
  PathContentReport: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/reports/content`,
  PathFeedbackReport: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/reports/feedback`,
  AdminPathDetails: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/details`,
  AdminPathPreviewDevices: (pathId: string) =>
    `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/preview-devices`,
  AdminPathPreview: (pathId: string) => `${ADMIN_PATH_PARENT_ROUTE}/${pathId}/preview`,
};

const learnerPathRoutes = {
  PathDetails: (pathId: string) => `${PARENT_ROUTE}/${pathId}/details`,
};

const routes = { ...adminPathRoutes, ...learnerPathRoutes };

export const ADMIN_PATH_ROUTE_KEYS = {
  PathCreator: 'PathCreator',
  PathEditor: 'PathEditor',
  PathReport: 'PathReport',
  PathLearnersReport: 'PathLearnersReport',
  PathContentReport: 'PathContentReport',
  PathFeedbackReport: 'PathFeedbackReport',
  AdminPathDetails: 'AdminPathDetails',
};

export const pathDetailRoute = (courseId: string) => ({
  admin: adminPathRoutes.AdminPathDetails(courseId),
  learner: learnerPathRoutes.PathDetails(courseId),
});

export const LEARNER_PATH_ROUTE_KEYS = {
  PathDetails: 'PathDetails',
};

export const PATH_ROUTE_KEYS = {
  ...ADMIN_PATH_ROUTE_KEYS,
  ...LEARNER_PATH_ROUTE_KEYS,
};

export default routes;
