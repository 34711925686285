import './tailwind.css';
import '../@/styles/common.css';
import '../@/styles/prism.css';

import React, { useEffect, useMemo, useState } from 'react';
import { QueryClient } from 'react-query';
import { SkipNavLink } from '@reach/skip-nav';
import * as Sentry from '@sentry/nextjs';
import config from 'config';
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import { Castoro, Caveat, Inter, Mulish, Poppins, Roboto_Mono } from 'next/font/google';
import Head from 'next/head';
import { withUrqlClient } from 'next-urql';
import ThemeProvider from 'theme';
import { getCSSVars } from 'theme/utils';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { jsonParse, jsonStringify } from '@lyearn/core/utils/json';
import { withHTTPProtocol } from '@lyearn/core/utils/network/url';
import i18nBuilder from '@lyearn/i18n';
import { Snackbar as SnackbarProvider, StylesProvider } from '@lyearn/ui';
import AppContainer from '@/components/AppContainer';
import BreadcrumbContext from '@/components/Breadcrumb/components/BreadcrumbContext';
import ConfigsProvider from '@/components/ConfigsProvider';
import { ErrorBoundary, PageError } from '@/components/Error';
import FreeTrialBanner from '@/components/FreeTrialBanner';
import QueryParamProvider from '@/components/QueryParamProvider';
import FeatureFlagsProvider from '@/helper/FeatureFlags/Provider';
import { fetchFeatureFlags } from '@/helper/FeatureFlags/utils/fetchFeatureFlags';
import setSecurityHeader from '@/helper/setSecurityHeader';
import getClient, { getExchanges } from '@/helper/urqlClient';
import registerPolyfills from '@/helper/web-polyfills';
import { AppConfig, AppConfigContext } from '@/hooks/useAppConfig';
import isomorphicAppConfig from '@/hooks/useAppConfig/isomorphicAppConfig';
import useNavigation from '@/hooks/useNavigation';
import useReloadOnUpdate from '@/hooks/useReloadOnUpdate';
import useSendLocation from '@/hooks/useSendLocation';
import { fetchUser } from '@/modules/auth/hooks/useFetchUser';
import { UserProfile } from '@/modules/auth/types';
import AuthRedirect from '@/pages/auth/AuthRedirect';
import getDefaultLayout from '@/pages/corporate/layout';
import PageResolver from '@/pages/corporate/PageResolver';
import { useAdminRoute } from '@/pages/corporate/routes/hooks/useAdminRoutes';
import { AppRoutes } from '@/routes';
import Bootstrap from '@/routes/Bootstrap';
import ClientProvider from '@/routes/Bootstrap/ClientProvider';
import { FeatureFlagType } from '@/types/schema';
import disableConsole from '@/utils/disableConsole';

registerPolyfills();

const inter = Inter({
  weight: ['400', '500', '600', '700'],
  display: 'swap',
  subsets: ['latin'],
});

const poppins = Poppins({
  weight: ['400', '500', '600', '700'],
  display: 'swap',
  subsets: ['latin'],
});

const nanumPenScript = Caveat({
  weight: ['400'],
  display: 'swap',
  subsets: ['latin'],
  preload: false,
});

const robotoMono = Roboto_Mono({
  display: 'swap',
  subsets: ['latin'],
  preload: false,
});

/**
 * Used only in SomosBelcorp (dynamically from themeConfig), not in @lyearn/ui or frontend-universe
 * So dont delete if you dont find any references
 */
const mulish = Mulish({
  display: 'swap',
  preload: false,
});

/**
 * Used only in COURSE, not in @lyearn/ui
 */
const castoro = Castoro({
  weight: ['400'],
  display: 'swap',
  subsets: ['latin'],
  preload: false,
});

export const FONTS: Record<string, typeof inter> = {
  '--font-inter': inter,
  '--font-poppins': poppins,
  '--font-pen': nanumPenScript,
  '--font-mulish': mulish,
  '--font-castoro': castoro,
  '--font-code': robotoMono,
};

function SafeHydrate({ children }: { children: React.ReactNode }) {
  return <div suppressHydrationWarning>{typeof window === 'undefined' ? null : children}</div>;
}

const queryClient = new QueryClient();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
  isPublicPage?: () => boolean;
};

type AppPropsWithLayout = AppProps<{
  appConfig: AppConfig;
  user: UserProfile;
  featureFlags: FeatureFlagType[];
}> & {
  Component: NextPageWithLayout;
};

const getUserLanguage = (userLanguages: readonly string[]) => {
  const supportedLanguages = ['en-US', 'es', 'hi', 'ar', 'mr', 'gu', 'kn', 'ta', 'te'];

  if (!userLanguages) {
    return 'en-US';
  }

  const supportedLanguage = userLanguages.find((language) => supportedLanguages.includes(language));

  return supportedLanguage || 'en-US';
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const navigation = useNavigation();
  const { appConfig, user, featureFlags } = pageProps;

  const isPublicPage = Component?.isPublicPage?.() || false;
  const getLayout = Component?.getLayout || getDefaultLayout(navigation.route);

  const [isI18nInitialized, setI18nInitialized] = useState(false);

  useEffect(() => {
    disableConsole(!!appConfig.isLoggingEnabled);
  }, [appConfig.isLoggingEnabled]);
  useEffect(() => {
    const language = localStorage?.getItem('language');
    i18nBuilder({
      lng: language ?? appConfig?.site.locale ?? getUserLanguage(navigator.languages),
    })
      .then((i18n) => {
        i18n.resolvedLanguage &&
          document.documentElement.setAttribute('lang', i18n.resolvedLanguage!);
        document.documentElement.setAttribute('dir', i18n.dir());
      })
      .finally(() => {
        setI18nInitialized(true);
      });
  }, [appConfig?.site.locale]);

  const isAdminRoute = useAdminRoute();

  useSendLocation();
  useReloadOnUpdate();
  const urqlClient = useMemo(
    () =>
      getClient({
        // Todo breaking usePagination's pageInfo when merging with old page info
        // enableURQLOfflineCache: appConfig.site.enableURQLOfflineCache,
      }),
    // in iframe when switching between admin and learner, urqlClient need to be reset since response is different based on X-learner header
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdminRoute],
  );

  if (!isI18nInitialized) {
    return null;
  }

  return (
    <SafeHydrate>
      <React.Suspense fallback={null}>
        <Sentry.ErrorBoundary>
          <Head>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                  :root {
                    ${getCSSVars(FONTS, appConfig.theme)}
                  }
              `,
              }}></style>
            <title key="title">{appConfig.site.name}</title>
            {appConfig.site?.favicon ? <link href={appConfig.site?.favicon} rel="icon" /> : null}
            <meta content={appConfig.site?.name} name="description" />
            <meta content="width=device-width, initial-scale=1" name="viewport" />
          </Head>
          <StylesProvider injectFirst>
            <AppContainer>
              <ThemeProvider
                isDarkModeAllowed={appConfig.ui.isDarkModeAllowed}
                themeConfig={appConfig.theme}>
                <ErrorBoundary fallback={PageError}>
                  <SnackbarProvider>
                    <AppConfigContext.Provider value={appConfig}>
                      <ConfigsProvider>
                        <QueryParamProvider>
                          <AuthRedirect>
                            <PageResolver>
                              <ClientProvider
                                queryClient={queryClient}
                                urqlClient={urqlClient}
                                user={user}>
                                <BreadcrumbContext>
                                  <FeatureFlagsProvider preloadedFeatureFlags={featureFlags}>
                                    <Bootstrap>
                                      <SkipNavLink className="body-short-03" />
                                      <FreeTrialBanner />
                                      {isPublicPage ? (
                                        getLayout(<Component {...(pageProps as any)} />)
                                      ) : (
                                        <AppRoutes>
                                          {getLayout(<Component {...(pageProps as any)} />)}
                                        </AppRoutes>
                                      )}
                                    </Bootstrap>
                                  </FeatureFlagsProvider>
                                </BreadcrumbContext>
                              </ClientProvider>
                            </PageResolver>
                          </AuthRedirect>
                        </QueryParamProvider>
                      </ConfigsProvider>
                    </AppConfigContext.Provider>
                  </SnackbarProvider>
                </ErrorBoundary>
              </ThemeProvider>
            </AppContainer>
          </StylesProvider>
        </Sentry.ErrorBoundary>
      </React.Suspense>
    </SafeHydrate>
  );
}

function logPromise<T>(promiseFn: Promise<T>, label: string, results: string[]): Promise<T> {
  if (__IS_BROWSER__) {
    return promiseFn;
  }

  const before = performance.now();

  return promiseFn.finally(() => {
    const after = performance.now();
    results.push(`${label};dur=${after - before}`);
  });
}

function sendResponse(appContext: AppContext, statusCode: number) {
  if (appContext.ctx.res) {
    appContext.ctx.res.statusCode = statusCode;
    appContext.ctx.res.end();
  }
  return { pageProps: {} };
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const serverHost = appContext.ctx.req?.headers.host;
  const clientHost = config.host_domain;
  const host = serverHost || clientHost;
  const serverTimings: string[] = [];

  // @ts-ignore
  const cookies = appContext.ctx.req?.cookies as Record<string, string> | undefined;

  // early return for sourcemap files. They are not needed on the server
  if (appContext.ctx.res && appContext.router.asPath.endsWith('.map')) {
    return sendResponse(appContext, 404);
  }

  if (!__IS_BROWSER__ && !serverHost) {
    ErrorReporter.error(new Error('could not find host on server'), (event) => {
      event.setExtra('headers', jsonStringify(appContext.ctx.req?.headers).data);

      return event;
    });
  }

  const { data: uiConfig } = jsonParse<AppConfig['ui'] | undefined>(
    appContext.router.query.uiConfig as string,
  );

  console.debug(
    'getInitialProps pathname: %s, url: %s',
    appContext.ctx.pathname,
    appContext.ctx.req?.url,
  );

  const hasUserCookies = cookies?._uid && cookies?.accessToken;
  try {
    const [appConfig, appProps, user, featureFlags] = await Promise.all([
      logPromise(
        isomorphicAppConfig(host!, uiConfig, !!appContext.router.query.isLoggingEnabled),
        'fetchAppConfig',
        serverTimings,
      ),
      logPromise(App.getInitialProps(appContext), 'getInitialProps', serverTimings),
      logPromise<void | UserProfile>(
        __IS_BROWSER__
          ? Promise.resolve()
          : hasUserCookies
          ? // @ts-ignore
            fetchUser(appContext.ctx.urqlClient, cookies._uid).catch(() => {
              /* on server refreshToken is not there so swallowing that error */
            })
          : Promise.resolve(),
        hasUserCookies ? 'fetchUser' : 'resolveStubUser',
        serverTimings,
      ),
      logPromise<void | FeatureFlagType[]>(
        __IS_BROWSER__
          ? Promise.resolve()
          : hasUserCookies
          ? // @ts-ignore
            fetchFeatureFlags(appContext.ctx.urqlClient)
          : Promise.resolve(),
        hasUserCookies ? 'fetchFeatureFlags' : 'resolveStubFeatureFlags',
        serverTimings,
      ),
    ]);
    const response = appContext.ctx.res;
    if (response && !response.headersSent) {
      setSecurityHeader(response, appConfig);
      response.setHeader('Server-Timing', serverTimings.join(', '));
    }

    return {
      ...appProps,
      pageProps: {
        ...appProps.pageProps,
        appConfig,
        user,
        featureFlags,
      },
    };
  } catch (err: unknown) {
    if (err instanceof Error && err.message === 'ACADEMY_NOT_FOUND') {
      return sendResponse(appContext, 404);
    }
    return sendResponse(appContext, 500);
  }
};

export default withUrqlClient((ssrExchange, ctx) => {
  return {
    url: `${withHTTPProtocol(ctx?.req?.headers.host || config.host_domain)}/api/graphql`,
    fetchOptions: () => {
      // @ts-ignore
      const cookies = (ctx?.req?.cookies as Record<string, string>) || undefined;
      const accessToken: string | undefined = cookies?.accessToken;

      if (__IS_BROWSER__ || !accessToken) {
        return {};
      }

      return { headers: { accessToken } };
    },
    exchanges: getExchanges(ssrExchange, false),
  };
  // @ts-ignore
})(MyApp);
