import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/article';
const ADMIN_ARTICLE_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const adminArticleRoutes = {
  ArticleBuilder: (articleId: string) => `${ADMIN_ARTICLE_PARENT_ROUTE}/${articleId}/build`,
  ArticlePreview: (articleId: string) => `${ADMIN_ARTICLE_PARENT_ROUTE}/${articleId}/preview`,
  ArticlePreviewDevices: (articleId: string) =>
    `${ADMIN_ARTICLE_PARENT_ROUTE}/${articleId}/preview-devices`,
  ArticleCreator: () => `${ADMIN_ARTICLE_PARENT_ROUTE}/create`,
  ArticleReport: (articleId: string) => `${ADMIN_ARTICLE_PARENT_ROUTE}/${articleId}/reports`,
  ArticleLearnersReport: (articleId: string) =>
    `${ADMIN_ARTICLE_PARENT_ROUTE}/${articleId}/reports/learners`,
  ArticleDetails: (articleId: string) => `${ADMIN_ARTICLE_PARENT_ROUTE}/${articleId}/details`,
};

const learnerarticleRoutes = {
  ArticleViewer: (articleId: string) => `${PARENT_ROUTE}/${articleId}/view`,
};

export const articleDetailRoute = (courseId: string) => ({
  admin: adminArticleRoutes.ArticleBuilder(courseId),
  learner: learnerarticleRoutes.ArticleViewer(courseId),
});

const routes = {
  ...adminArticleRoutes,
  ...learnerarticleRoutes,
};

export const ADMIN_ARTICLE_ROUTE_KEYS = {
  ArticleBuilder: 'articleBuilder',
  ArticleCreator: 'articleCreator',
  ArticleReport: 'articleReport',
  ArticlePreview: 'articlePreview',
  ArticleLearnersReport: 'articleLearnersReport',
};

export const LEARNER_ARTICLE_ROUTE_KEYS = {
  ArticleViewer: 'articleViewer',
};

export const ARTICLE_ROUTE_KEYS = {
  ...ADMIN_ARTICLE_ROUTE_KEYS,
  ...LEARNER_ARTICLE_ROUTE_KEYS,
};

export default routes;
