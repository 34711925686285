import React, { useMemo } from 'react';

import { Stack } from '@lyearn/ui';
import useNavigation from '@/hooks/useNavigation';
import routes from '@/pages/corporate/routes';

import { SidebarItems } from '../../hooks/useSidebar';
import Sidebar from '../Sidebar';
import Content from './Content';

interface AdminProps {
  sidebarItems: SidebarItems;
  children: React.ReactNode;
}

const SIDEBAR_ENABLED_ROUTES = [
  routes.LearningContent(),
  routes.RecentLearningContent(),
  routes.LearningContentDraft(),
  routes.LearningContentActive(),
  routes.LearningContentArchived(),
  routes.Dashboard(),
  routes.ViewAcademy(),
  routes.Learners(),
  routes.ActiveLearners(),
  routes.PendingLearners(),
  routes.DeactivatedLearners(),
  routes.GroupManager(),
  routes.CollectionManager(),
  routes.Organization(),
  routes.Assignments(),
  routes.SkillsAndMastery(),
  routes.ScorecardManager(),
  routes.LyearnConnectManager(),
  routes.AdminCertificates(),
  routes.TagsManager(),
  routes.Staff(),
  routes.ActiveStaff(),
  routes.PendingStaff(),
  routes.DeactivatedStaff(),
  routes.Reports(),
  routes.StandardReports(),
  routes.CustomReports(),
  routes.Settings(),
  routes.AdminEvents(),
  routes.ManageUpcomingEvents(),
  routes.ManageFinishedEvents(),
  routes.ManageRecurringEvents(),
  routes.Settings(),
  routes.StaffRoleManager(),
  routes.CycleManager(),
  routes.GettingStarted(),
  routes.SkillMatrixManager(),
  routes.SkillMatrixArchived(),
  routes.SkillsManager(),
  routes.DesignationManager(),
  routes.IntegrationsManager(),
  routes.NotificationsManager(),
  routes.CategoriesManager(),
  routes.ReviewManager('[tab]'),
  routes.ReviewManagerOrganisational('[tab]'),
  routes.ReviewManagerProject('[tab]'),
  routes.ReviewManagerRuleBased('[tab]'),
  routes.ReviewQuestionBank(),
  routes.AutomatedReviewActionPlan(),
  routes.DepartmentManager(),
  routes.MindsetManager(),
  routes.TaskStatusesManager(),
  routes.MetricsManager(),
  routes.AdminQuestionManager('[tab]'),
  routes.SystemAgendaManager(),
  routes.UserSurveys(),
  routes.UserSurveyManager('[type]', '[tab]'),
  routes.UserSurveysQuestionBank(),
  routes.UserSurveyActionPlans(),
  routes.JourneyManager(),
  routes.AnnouncementManager(),
  routes.KnowledgeAssetPersonaManager('[type]'),
];

function Admin(props: AdminProps) {
  const { sidebarItems, children } = props;
  const navigation = useNavigation();

  const showSidebar = useMemo(
    () => SIDEBAR_ENABLED_ROUTES.some((route) => route === navigation.pathname),
    [navigation.pathname],
  );
  return (
    <Stack className="flex-1">
      {showSidebar ? <Sidebar sidebarItems={sidebarItems} /> : null}
      <Content>{children}</Content>
    </Stack>
  );
}

export default Admin;
