import React from 'react';
import { useScroll } from 'react-use';
import _mergeWith from 'lodash/mergeWith';

import { cn } from '@lyearn/style';
import { Box } from '@lyearn/ui';
import { DialogComponent } from '@/components/DialogComponent';
import { DIALOG_PAPER_CLASSES } from '@/components/DialogComponent/style';

import { ModalProps, ModalVariant } from '../types';
import ModalActions from './ModalActions';
import ModalHeader from './ModalHeader';
import { extraWideModalStyles, fullScreenModalStyles, modalStyles, wideModalStyles } from './style';

const dialogPaperClass = cn(DIALOG_PAPER_CLASSES, modalStyles);

const dialogClasses = {
  paper: dialogPaperClass,
};

const fullHeightDialogClasses = {
  paper: cn(dialogPaperClass, 'full-height'),
};

export const fullScreenDialogClasses = {
  paper: cn(DIALOG_PAPER_CLASSES, fullScreenModalStyles),
};

const extraWideDialogClasses = {
  paper: cn(DIALOG_PAPER_CLASSES, extraWideModalStyles),
};

const wideDialogClasses = {
  paper: cn(DIALOG_PAPER_CLASSES, wideModalStyles),
};

const CLOSE_BUTTON_PROPS = { size: 'large' as const, className: 'mt-16 me-12' };

const getClasses = (variant?: ModalVariant) => {
  switch (variant) {
    case ModalVariant.FullHeight:
      return fullHeightDialogClasses;
    case ModalVariant.FullScreen:
      return fullScreenDialogClasses;
    case ModalVariant.ExtraWide:
      return extraWideDialogClasses;
    case ModalVariant.Wide:
      return wideDialogClasses;
    default:
      return dialogClasses;
  }
};

function Modal(props: ModalProps) {
  const {
    onClose,
    children,
    variant,
    header,
    renderHeader,
    renderSubFooter,
    action,
    TransitionComponent,
    dialogProps,
    closeButton,
    open = true,
    bottomSheetContainerClassName,
    classes,
    showCloseIcon = true,
    bottomSheetClasses,
    className,
    hideOnScroll = true,
    showCloseBtn = true,
    root,
    footer,
    renderAs,
  } = props;

  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);

  const { y } = useScroll({
    current: ref,
  });
  const isScrolled = y > 0 && hideOnScroll;

  const mergedClasses = React.useMemo(
    () => _mergeWith({}, getClasses(variant), classes, cn),
    [classes, variant],
  );

  return (
    <DialogComponent
      TransitionComponent={TransitionComponent}
      bottomSheetClasses={bottomSheetClasses}
      bottomSheetContainerClassName={bottomSheetContainerClassName}
      className={className}
      classes={mergedClasses}
      closeButton={closeButton ?? CLOSE_BUTTON_PROPS}
      dialogProps={dialogProps}
      open={open}
      renderAs={renderAs}
      root={root}
      showCloseIcon={showCloseIcon}
      onClose={onClose}>
      <Box className="z-40 flex h-full flex-1 flex-col overflow-auto rounded-16">
        {renderHeader ? (
          renderHeader(header)
        ) : header ? (
          <ModalHeader header={header} isScrolled={isScrolled} />
        ) : null}
        <Box ref={setRef} className={cn('flex h-full flex-1 flex-col overflow-y-auto', className)}>
          {children}
        </Box>
        {action ? (
          <ModalActions
            action={action}
            isScrolled={isScrolled}
            showCloseBtn={showCloseBtn}
            variant={variant}
            onClose={onClose}
          />
        ) : null}
        {footer}
        {renderSubFooter ? renderSubFooter() : null}
      </Box>
    </DialogComponent>
  );
}

export default Modal;
